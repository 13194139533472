import '../fonts/Inter-Black.woff2';
import '../fonts/Inter-Bold.woff2';
import '../fonts/Inter-ExtraBold.woff2';
import '../fonts/Inter-ExtraLight.woff2';
import '../fonts/Inter-Light.woff2';
import '../fonts/Inter-Medium.woff2';
import '../fonts/Inter-Regular.woff2';
import '../fonts/Inter-SemiBold.woff2';
import '../fonts/Inter-Thin.woff2';

import '../fonts/DarkerGrotesque-Black.woff2';
import '../fonts/DarkerGrotesque-Bold.woff2';
import '../fonts/DarkerGrotesque-ExtraBold.woff2';
import '../fonts/DarkerGrotesque-Light.woff2';
import '../fonts/DarkerGrotesque-Medium.woff2';
import '../fonts/DarkerGrotesque-Regular.woff2';
import '../fonts/DarkerGrotesque-SemiBold.woff2';

import '../fonts/IBMPlexMono-Bold.woff2';
import '../fonts/IBMPlexMono-BoldItalic.woff2';
import '../fonts/IBMPlexMono-ExtraLight.woff2';
import '../fonts/IBMPlexMono-ExtraLightItalic.woff2';
import '../fonts/IBMPlexMono-Italic.woff2';
import '../fonts/IBMPlexMono-Light.woff2';
import '../fonts/IBMPlexMono-LightItalic.woff2';
import '../fonts/IBMPlexMono-Medium.woff2';
import '../fonts/IBMPlexMono-MediumItalic.woff2';
import '../fonts/IBMPlexMono-Regular.woff2';
import '../fonts/IBMPlexMono-SemiBold.woff2';
import '../fonts/IBMPlexMono-SemiBoldItalic.woff2';
import '../fonts/IBMPlexMono-Thin.woff2';
import '../fonts/IBMPlexMono-ThinItalic.woff2';

import '../sass/main.scss';

import './common/custom-select.js';
import './common/focus.js';
import './common/screen-width.js';
import './common/screen-height.js';
import './common/css-variables.js';

import './layouts/cookies-notice.js';
import './layouts/site-menu.js';
import './layouts/site-top-gradient.js';

import './common/on-screen.js';

import { initModals } from './common/modals.js';

(() => {
    initModals();
})();
